import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '../views/login/LoginView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    redirect: '/main',
    children: [
      {
        path: '/main',
        name: 'main',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/MainView.vue')
      },
    ]
  },
  // 普通用户的router
  {
    path: '/user-home',
    name: 'user-home',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/HomeView.vue'),
    redirect: '/user-main',
    children: [
      //主页面
      {
        path: '/user-main',
        name: 'user-main',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/MainView.vue')
      },
      // 协议管理——污染物代码
      {
        path: '/factor-management',
        name: 'factor-management',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/facterManager.vue'),
      },
      // 站点管理——站点列表
      {
        path: '/site-siteList',
        name: 'site-siteList',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/siteManagement.vue'),
      },
      // 数据分析——历史数据
      {
        path: '/data-history',
        name: 'data-history',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/data/dataHistory.vue'),
      },
       // 数据分析——数据图表
       {
        path: '/data-Charts',
        name: 'data-Charts',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/data/dataCharts.vue'),
      },
      // 数据分析——日志
      {
        path: '/data-rz',
        name: 'data-rz',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/data/dataRZ.vue'),
      },
      // 数据分析——状态
      {
        path: '/data-zt',
        name: 'data-zt',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/data/dataZT.vue'),
      },
      // 数据分析——报警
      {
        path: '/data-bj',
        name: 'data-bj',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/data/dataBj.vue'),
      },
      // 数据分析——报警
      {
        path: '/data-warn',
        name: 'data-warn',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/data/dataWarn.vue'),
      },
      // 日志中心 ——短信
      {
        path: '/log-msg',
        name: 'log-msg',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/logMsg.vue'),
      },
      // 日志中心 ——废水参数信息
      {
        path: '/log-wasteWater-parameter',
        name: 'log-wasteWater-parameter',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/logWasteWater.vue'),
      },
      // 系统设置——修改密码
      {
        path: '/updatePwd',
        name: 'updatePwd',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/updatePwd.vue'),
      },
      // 系统设置——修改数据页面显示
      {
        path: '/dataUI-setting',
        name: 'dataUI-setting',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/data/dataUISetting.vue'),
      },
      // 系统设置——给用户分配设备
      {
        path: '/user-setting',
        name: 'user-setting',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserSettingView.vue'),
      },
    ]
  },
  // 附属用户的router
  {
    path: '/affiliatedUser-home',
    name: 'affiliatedUser-home',
    component: () => import(/* webpackChunkName: "about" */ '../views/affiliatedUser/HomeView.vue'),
    redirect: '/affiliatedUser-main',
    children: [
      //主页面
      {
        path: '/affiliatedUser-main',
        name: 'affiliatedUser-main',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/affiliatedUser/MainView.vue')
      },
      {
        path: '/affiliatedUser-data-history',
        name: 'affiliatedUser-data-history',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/affiliatedUser/dataHistory.vue')
      },
      {
        path: '/affiliatedUser-data-warn',
        name: 'affiliatedUser-data-warn',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/affiliatedUser/dataWarn.vue')
      },
      {
        path: '/affiliatedUser-log-msg',
        name: 'affiliatedUser-log-msg',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/affiliatedUser/logMsg.vue')
      },
      {
        path: '/affiliatedUser-wasteWater-parameter',
        name: 'affiliatedUser-wasteWater-parameter',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/affiliatedUser/logWasteWater.vue')
      },
      {
        path: '/affiliatedUser-updatePwd',
        name: 'affiliatedUser-updatePwd',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/affiliatedUser/updatePwd.vue')
      },
      {
        path: '/affiliatedUser-dataUI-setting',
        name: 'affiliatedUser-dataUI-setting',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/affiliatedUser/dataUISetting.vue')
      },
    ]
  },
  // 凯米斯用户的router
  {
    path: '/cheminsUser-home',
    name: 'cheminsUser-home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/cheminsUser/HomeView.vue'),
    redirect: '/cheminsUser-main',
    children: [
      //主页面
      {
        path: '/cheminsUser-main',
        name: 'cheminsUser-main',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/cheminsUser/MainView.vue')
      },
      // 数据分析——历史数据
      {
        path: '/cheminsUser-data-history',
        name: 'cheminsUser-data-history',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/cheminsUser/dataHistory.vue')
      },
      // 站点管理——站点列表
      {
        path: '/cheminsUser-siteManagement',
        name: 'cheminsUser-siteManagement',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/cheminsUser/siteManagement.vue'),
      },
      // 数据分析——报警
      {
        path: '/cheminsUser-data-warn',
        name: 'cheminsUser-data-warn',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/cheminsUser/dataWarn.vue'),
      },
      // 日志中心 ——短信
      {
        path: '/cheminsUser-log-msg',
        name: 'cheminsUser-log-msg',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/cheminsUser/logMsg.vue'),
      },
      // 系统设置——修改密码
      {
        path: '/cheminsUser-updatePwd',
        name: 'cheminsUser-updatePwd',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/cheminsUser/updatePwd.vue'),
      },
    ]
  },
   // 扬尘用户的router
   {
    path: '/yangchenUser-home',
    name: 'yangchenUser-home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/YangChenUser/HomeView.vue'),
    redirect: '/yangchenUser-main',
    children: [
      //主页面
      {
        path: '/yangchenUser-main',
        name: 'yangchenUser-main',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/YangChenUser/MainView.vue')
      },
      // 数据分析——历史数据
      {
        path: '/yangchenUser-data-history',
        name: 'yangchenUser-data-history',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/YangChenUser/dataHistory.vue')
      },
      // 站点管理——站点列表
      {
        path: '/yangchenUser-siteManagement',
        name: 'yangchenUser-siteManagement',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/YangChenUser/siteManagement.vue'),
      },
      // 系统设置——修改密码
      {
        path: '/yangchenUser-updatePwd',
        name: 'yangchenUser-updatePwd',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/YangChenUser/updatePwd.vue'),
      },
    ]
  },
  //邯郸凯米斯用户的router
  {
    path: '/login-API',
    name: 'login-API',
    component: () => import(/* webpackChunkName: "about" */ '@/views/HDcheminsUser/LoginView.vue'),
  },
  {
    path: '/HDcheminsUser-home',
    name: 'HDcheminsUserhome',
    component: () => import(/* webpackChunkName: "about" */ '@/views/HDcheminsUser/HomeView.vue'),
    redirect: '/HDcheminsUser-main',
    children: [
      {
        path: '/HDcheminsUser-main',
        name: 'HDcheminsUser-main',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/HDcheminsUser/MainView.vue')
      },
      {
        path: '/HDcheminsUser-data-history',
        name: 'HDcheminsUser-data-history',
        meta: {
          isShow: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/HDcheminsUser/dataHistory.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
